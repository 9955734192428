
import { Options, Vue } from 'vue-class-component'
import AdminCompetitions from '@/components/Competitions/AdminCompetitions/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminCompetitions
  }
})
export default class CompetitionsView extends Vue {}
