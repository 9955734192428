
import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Products extends APIBaseFunctions {
  static ProductsDataService = class {
    private static cachedProductsDataDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedProductsDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/produkts?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/produkts?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/produkts/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/produkts/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/produkts', data)
    }

    static update (id: string, data: any) {
      return http.put(`/produkts/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/produkts/${id}`)
    }

    static deleteAll () {
      return http.delete('/produkts')
    }

    static findByProductStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedProductsDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/produkts?produkt_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedProductsDataDataSliceObj }, dataSliceObj)
      searchTerm = searchTerm.trim()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        // Handle telephone number and license number in search term
        if (ignoreStatus) {
          retVal = http.get(`/produkts?_where[_or][0][produkt_pris]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/produkts?produkt_status=${status}&_where[_or][0][produkt_pris]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else {
        if (ignoreStatus) {
          retVal = http.get(`/produkts?_where[_or][0][produkt_navn_contains]=${searchTerm}&_where[_or][1][produkt_beskrivelse_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/produkts?produkt_status=${status}&_where[_or][0][produkt_navn_contains]=${searchTerm}&_where[_or][1][produkt_beskrivelse_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      }

      return retVal
    }
  }

  static ProductTypesDataService = class {
    private static cachedProductTypesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedProductTypesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/produkt-types?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/produkt-types?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/produkt-types/${id}`)
    }

    static getCount () {
      return http.get('/produkt-types/count')
    }

    static create (data: any) {
      return http.post('/produkt-types', data)
    }

    static update (id: string, data: any) {
      return http.put(`/produkt-types/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/produkt-types/${id}`)
    }

    static deleteAll () {
      return http.delete('/produkt-types')
    }

    static findByProductTypeStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedProductTypesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/produkt-types?produkt_type_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedProductTypesDataSliceObj }, dataSliceObj)
      searchTerm = searchTerm.trim()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/produkt-types?_where[_or][0][produkt_type_navn_contains]=${searchTerm}&_where[_or][1][produkt_type_beskrivelse_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/produkt-types?produkt_type_status=${status}&_where[_or][0][produkt_type_navn_contains]=${searchTerm}&_where[_or][1][produkt_type_beskrivelse_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static AccountsDataService = class {
    private static cachedAccountsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedAccountsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`kontis?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kontis?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kontis/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/kontis/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/kontis', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kontis/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kontis/${id}`)
    }

    static deleteAll () {
      return http.delete('/kontis')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Products.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedAccountsDataSliceObj }, dataSliceObj)
      let sortingOrderParameter = ''
      searchTerm = searchTerm.trim()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      const retVal = http.get(`/kontis?_where[_or][0][konti_navn_contains]=${searchTerm}&_where[_or][1][konti_nummer_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      return retVal
    }
  }
}

export default new Products()
